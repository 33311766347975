<template>
  <b-modal v-if="exam" :id="modalId" >
    <template v-slot:modal-title>
      <div class="w-color-primary m-0 pl-1">Zdieľanie testu</div>
    </template>
    <template v-slot:modal-header-close>
      <b-link
        class="a-icon-wrapper border-0 a-color-primary"
        href="#"
        @click="close()"
      >
        <img src="@/plugins/app/_theme/icon/close.svg" />
      </b-link>
    </template>

    <div class="d-flex justify-content-between border-bottom py-3">
      <span>Názov testu</span>
      <span><b>{{ exam.name }}</b></span>
    </div>

    <b-form>
      <b-form-input
        class="border-0 mt-3 px-0"
        placeholder="Sem napíšte mailové adresy učiteľov (oddelené čiarkami), ktorým chcete zdieľať test."
        name="nameExam"
        v-model="emails"
      ></b-form-input>
    </b-form>
    
    <template v-slot:modal-footer>
      <b-button class="button mt-3" variant="outline-primary" @click="send">Odoslať</b-button>
    </template>
  </b-modal>
</template>

<script>
import wAxios from '@/plugins/w/axios'

const api = {
  share: (examId, data) => wAxios.post_auth(`/v1/teacher/examtemplates/${examId}/share`, data)
}

export default {
  props: {
    exam: { required: true },
    modalId: { required: true }
  },

  data() {
    return {
      emails: ''
    }
  },

  methods: {
    async send() {
      if (!this.exam) {
        return
      }

      const data = {
        recipients: this.emails.replace(/\s/g, '').split(',')
      }

      try {
        await api.share(this.exam.id, data)
        this.$bvToast.toast(
          'Test bol úspešne zdieľaný!', 
          {
            title: 'Podarilo sa!',
            autoHideDelay: 3000,
            variant: 'a-success',
            toaster: 'b-toaster-top-center',
            solid: true
          }
        )
      } catch (err) {
        this.$wToast.error(err)
      } finally {
        this.close()
      }
    },

    close() {
      this.$bvModal.hide(this.modalId)
    }
  }
}
</script>

<style lang="sass" scoped>
footer
  border-top: 1px solid var(--a-color-border-grey)
</style>
